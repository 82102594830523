import React from 'react';
import { BsGithub, BsLinkedin } from 'react-icons/bs';
import { FaHandshake } from 'react-icons/fa';

const SocialMedia = () => {
  return (
    <div className="app__social">
        <div>
          <a href="https://github.com/choshingcheung"><BsGithub /></a>
        </div>
        <div>
          <a href="https://ucsd.joinhandshake.com/profiles/58256489"><FaHandshake /></a>
        </div>
        <div>
          <a href="https://www.linkedin.com/in/choshingcheung/"><BsLinkedin /></a>
        </div>
    </div>
  )
}

export default SocialMedia