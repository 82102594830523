import React from 'react';
import { motion } from 'framer-motion';

import { AppWrap } from '../../wrapper';
import './Header.scss';

const Header = () => {
  return (

    <div className="app__header app__flex">
      <div className="app__header-content">

        <motion.div
          whileInView={{ x: [-100, 0], opacity: [0, 1] }}
          transition={{ duration: 0.5 }}
          className="app__header-info"
        >
          <h1 className="header-title">Welcome, I am</h1>
          <h2 className="header-name">Jason Cheung</h2>
          <p className="header-description">
            A Math-CS student from UCSD, passionate about coding and building innovative solutions to solve real life problems. 
            Experienced in Software and Web Development, and have experiences in leadership and communication.
          </p>

          <a href="/resume.pdf" className="header-button" target="_blank" rel="noopener noreferrer">
            View My Resume
          </a>
        </motion.div>
      </div>
    
      <div className="app__header-image">
      </div>

    </div>
  )
}

export default AppWrap(Header, 'home');